export type SalesforceCloud =
  | 'Gamma'
  | 'Kappa'
  | 'CI'
  | 'Iota'
  | 'Beta'
  | 'OpenSync'
  | 'Dogfood'
  | 'Osync'
  | 'Phi'

/**
 * When one of these products is purchased, portal is not in trial
 */
export const salesforcePortalProducts = [
  'Haystack',
  'Frontline',
  'Panorama',
  'Signal',
  'HomePass',
  'WorkPass',
  'Harvest',
  'Clarity',
  'Generate',
  'Nurture',
  'Uprise',
  'Flex',
]

export const salesforcePrimaryClouds: SalesforceCloud[] = ['Gamma', 'Kappa', 'CI', 'Iota', 'Phi']
