import axios from 'axios'
import { statusPageUrls } from 'constants/statuspage-urls'
import { SalesforceCloud } from 'constants/salesforce.constants'
import { ICloudStatusResponse } from 'interfaces/api/statuspage'

export async function getCloudStatus(cloud: SalesforceCloud) {
  const validCloud = ['Beta', 'Kappa', 'Gamma', 'Iota', 'Phi'].includes(cloud)
  if (!validCloud) {
    return null
  }
  const url = statusPageUrls[cloud]

  try {
    const { data } = await axios.get<ICloudStatusResponse>(url)
    return data
  } catch (e) {
    throw new Error(e.message)
  }
}
